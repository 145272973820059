<template>
  <div class="p-2 h-100 d-flex flex-column">
    <!--  IMAGE   -->
    <p v-show="cart.length > 0" class="text-center">
      {{ $t("reservationExpiresAt") }}:
      <TCountdown :end="expirationTime"></TCountdown>
    </p>
    <div v-if="cart.length > 0" class="d-flex flex-column flex-grow-1">
      <div class="d-flex flex-column flex-grow-1">
        <div class="d-flex justify-content-between mb-3">
          <span class="h4 mb-0">{{ $t("cart.title") }}</span>

          <font-awesome-icon
            :icon="['fal', 'trash-alt']"
            class=" pointer align-self-center text-warning"
            @click="restartFlow"
            :title="$t('cart.action.empty')"
          ></font-awesome-icon>
        </div>
        <div v-for="(event, index) in cart" :key="index">
          <h5>{{ event["info"].name }}</h5>

          <div v-for="(show, showIndex) in event['shows']" :key="showIndex">
            <div
              class="font-weight-bold mb-1 d-inline-block text-uppercase-first"
            >
              {{
                show["showInfo"].startsAt.toLocaleString(
                  $dateTimeFormat.DATETIME_WITH_WEEKDAY
                )
              }}
            </div>
            <div
              v-for="(ticket, ticketIndex) in show['tickets']"
              :key="'ticket-' + ticketIndex"
              style="display: grid; grid-template-columns: 2fr 1fr 1fr;"
            >
              <span>{{ ticket[0].name }}</span>
              <span class="text-center">{{ ticket.length }}</span>
              <span
                class="text-right"
                :class="{
                  'text-success': ticket[0].price * ticket.length === 0
                }"
              >
                <TCurrency
                  :amount="ticket[0].price * ticket.length"
                ></TCurrency>
              </span>
            </div>
          </div>
          <h5 class="mt-3" v-if="event['additionalItems'].length > 0">
            {{ $t("cart.additionalItems") }}
          </h5>
          <div
            v-for="(item, itemIndex) in event['additionalItems']"
            :key="'additional-item-' + itemIndex"
            style="display: grid; grid-template-columns: 2fr 1fr 1fr;"
          >
            <span>{{ item[0].name }} {{ item[0].description }}</span>
            <span class="text-center">{{ item.length }}</span>
            <span
              class="text-right"
              :class="{
                'text-success': item[0].price * item.length === 0
              }"
            >
              <TCurrency :amount="item[0].price * item.length"></TCurrency>
            </span>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <div class="text-warning small" v-show="couponInvalid">
          {{ $t("coupon.error.invalid") }}
        </div>
        <div class="text-warning small" v-show="couponRequiresUser">
          {{ $t("coupon.error.requiresUser") }}
        </div>
        <div class="input-group mb-3">
          <input
            class="form-control form-control-sm text-uppercase"
            type="text"
            v-model="couponToRedeem"
            :placeholder="$t('coupon.coupon')"
          />
          <div class="input-group-append">
            <button
              class="btn btn-success btn-sm"
              :class="{ disabled: couponToRedeem.length === 0 }"
              @click="redeemCoupon"
            >
              {{ $t("coupon.action.redeem") }}
            </button>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <span>{{ $t("cart.feesOnOrder") }}</span>
          <span
            :class="{
              'text-success': totalFees === 0
            }"
          >
            <TCurrency :amount="totalFees"></TCurrency>
          </span>
        </div>

        <div class="d-flex justify-content-between" v-if="discount !== null">
          <span
            >{{ $t("cart.discount") }} <i>{{ discount.coupon }}</i></span
          >
          <TCurrency :amount="discount.amount"></TCurrency>
        </div>

        <div class="d-flex justify-content-between font-weight-bold">
          <span>{{ $t("cart.sumOfOrder") }}</span>
          <span
            :class="{
              'text-success': totalPrice === 0
            }"
          >
            <TCurrency :amount="totalPrice"></TCurrency>
          </span>
        </div>
      </div>
      <div v-show="showGoToPaymentButton">
        <button class="btn btn-success btn-block mt-3" @click="goToPayment">
          {{ $t("payment.action.pay") }}
        </button>
      </div>
    </div>
    <p v-if="cart.length === 0" class="text-center text-muted mt-4">
      {{ $t("cart.isEmpty") }}
    </p>
  </div>
</template>

<script>
import { TCountdown, TCurrency } from "@tickethero/vue-component-kit";
import { RestartMixin } from "@/mixins/RestartMixin";

export default {
  name: "Cart",
  components: { TCountdown, TCurrency },
  mixins: [RestartMixin],
  data: function() {
    return {
      couponToRedeem: "",
      couponInvalid: false,
      couponRequiresUser: false
    };
  },
  computed: {
    showGoToPaymentButton() {
      if (this.cart.length === 0) {
        return false;
      }
      if (
        ["Payment", "BuyerInformation", "OrderQuestion"].includes(this.page)
      ) {
        return false;
      }
      return true;
    },
    page: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/global/page"
      ];
    },
    tickets: function() {
      return this.$store.state[this.$store.state.shownWidget].order.tickets;
    },
    additionalItems: function() {
      return this.$store.state[this.$store.state.shownWidget].ordeer
        .additionalItems;
    },
    discount: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/order/discount"
      ];
    },
    cart: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/order/groupedCart"
      ];
    },
    expirationTime: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/order/expirationTime"
      ];
    },
    totalPrice: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/order/totalPrice"
      ];
    },
    totalFees: function() {
      return this.$store.getters[
        this.$store.state.shownWidget + "/order/totalFees"
      ];
    }
  },
  methods: {
    redeemCoupon() {
      this.couponInvalid = false;
      this.couponRequiresUser = false;
      if (this.couponToRedeem === "") {
        return;
      }
      this.$store
        .dispatch(this.$store.state.shownWidget + "/order/redeemCoupon", {
          coupon: this.couponToRedeem
        })
        .then(() => {
          this.couponToRedeem = "";
        })
        .catch(e => {
          if (e?.response?.data?.error?.code === 5000000) {
            this.couponToRedeem = "";
            this.$store.dispatch(
              this.$store.state.shownWidget + "/order/fetchCart"
            );
            this.$store.commit(
              this.$store.state.shownWidget + "/global/changePage",
              "AdditionalItems"
            );
          } else if (e?.response?.data?.error?.code === 1000004) {
            this.couponRequiresUser = true;
          } else {
            this.couponInvalid = true;
          }
        });
    },
    goToPayment() {
      this.$store.commit(
        this.$store.state.shownWidget + "/global/changePage",
        "BuyerInformation"
      );
    }
  }
};
</script>
