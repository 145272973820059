<template>
  <span v-cloak class="root">
    <!--  static and lazy properties are set to keep the modal inside the web component, as to make sure style is applied correctly  -->
    <!-- the modal-class overflow-auto is to allow scrolling, when modal is being static -->
    <b-modal
      v-model="showModal"
      body-class="position-relative p-0"
      modal-class="modal-backdrop-class overflow-auto backdrop-filter backdrop-blur-3 backdrop-grayscale-50"
      hide-footer
      hide-header
      no-close-on-backdrop
      centered
      size="xl"
      static
      lazy
    >
      <div class="container-fluid">
        <ModalOverlayMessage v-if="reservationExpired">
          <h2>{{ $t("reservationExpired.title") }}</h2>
          <button class="btn btn-primary" @click="restartFlow">
            {{ $t("reservationExpired.action.startOver") }}
          </button>
        </ModalOverlayMessage>
        <ModalOverlayMessage v-if="saleIsStopped">
          <h2>{{ $t("saleHasEnded.title") }}</h2>
        </ModalOverlayMessage>
        <div class="h-100 row">
          <div class="col" :class="{ 'col-12 col-lg-8': !hideCart }">
            <div class="row h-100 flex-column">
              <page-header></page-header>
              <transition name="fade" mode="out-in">
                <component :is="page" v-cloak></component>
              </transition>
            </div>
          </div>
          <div class="col-12 col-lg-4 bg-secondary cart" v-if="!hideCart">
            <cart></cart>
          </div>
        </div>
        <div
          class="position-absolute col-12 pt-1"
          style="left: 0; right: 0; top: 100%;"
        >
          <div class="row">
            <div class="col-6 pl-0">
              <span
                class="pointer"
                v-for="language in availableLanguages"
                :key="language.key"
                @click="changeLanguage(language)"
                :class="{ 'font-weight-bold': systemLanguage === language.key }"
              >
                {{ language.name }}
              </span>
            </div>
            <div class="col-6 text-right pr-0">
              {{ footerText }}
            </div>
          </div>
        </div>
        <div
          class="position-absolute col-12"
          style="right: -20px; top: -20px;"
          v-if="!forceOpen"
        >
          <div class="row">
            <div class="col-12 text-right pr-0">
              <FontAwesomeIcon
                :icon="['fal', 'times-circle']"
                size="lg"
                class="pointer"
                @click="showModal = false"
              ></FontAwesomeIcon>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <link rel="stylesheet" :href="theme" v-if="theme !== ''" />
    <button
      class="btn btn-success"
      @click="openModal"
      v-if="!forceOpen && !problemInitializingWidget && widgetSettingsFetched"
    >
      <slot>
        {{ $t("action.tickets") }}
      </slot>
    </button>
    <div v-if="problemInitializingWidget">
      {{ $t("error.problemInitializingWidget") }}
    </div>
  </span>
</template>

<script>
import PageHeader from "@/components/PageHeader";
require("./bootstrap");
import { i18n } from "@/bootstrap";

import store from "@/store";
import Index from "@/pages/Index";
import { BModal } from "bootstrap-vue";
import Cart from "@/components/Cart";
import ShowList from "@/pages/ShowList";
import Tickets from "@/pages/Tickets";
import AdditionalItems from "@/pages/AdditionalItems";
import BuyerInformation from "@/pages/BuyerInformation";
import Payment from "@/pages/Payment";
import Calendar from "@/pages/Calendar";
import OrderQuestion from "@/pages/OrderQuestion";

import groupBy from "lodash.groupby";
import { config, dom } from "@fortawesome/fontawesome-svg-core";
import ModalOverlayMessage from "@/components/ModalOverlayMessage";
import { Global } from "@/store/global";
import { Order } from "@/store/order";
import { Event } from "@/store/event";
import { RestartMixin } from "@/mixins/RestartMixin";
import { Settings } from "luxon";
config.autoAddCss = false;
export default {
  i18n,
  store,
  name: "App",
  components: {
    ModalOverlayMessage,
    Cart,
    PageHeader,
    Index,
    BModal,
    ShowList,
    Tickets,
    AdditionalItems,
    BuyerInformation,
    OrderQuestion,
    Payment,
    Calendar
  },
  mixins: [RestartMixin],
  props: {
    widgetId: {
      type: String,
      default: process.env.VUE_APP_TEST_WIDGET_UUID
    },
    instance: {
      type: String,
      default: ""
    },
    forceOpen: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      showModal: false,
      problemInitializingWidget: false,
      widgetSettingsFetched: false,
      availableLanguages: [
        {
          key: "da",
          name: "Dansk"
        },
        {
          key: "en",
          name: "English"
        }
      ]
    };
  },
  computed: {
    page: function() {
      return this.$store.getters[
        this.widgetId + this.instance + "/global/page"
      ];
    },
    reservationExpired: function() {
      return this.$store.getters[
        this.widgetId + this.instance + "/order/reservationExpired"
      ];
    },
    theme: function() {
      return this.$store.getters[
        this.widgetId + this.instance + "/global/themeUri"
      ];
    },
    saleIsStopped: function() {
      return this.$store.getters[
        this.widgetId + this.instance + "/event/saleIsStopped"
      ];
    },
    hideCart() {
      return ["payment"].includes(this.page.toLowerCase());
    },
    footerText() {
      const options = this.$t("poweredBy");
      const result = (this.widgetId + this.instance).match(/\d+/g);
      if (result === null) {
        return options[0];
      }
      const index = (this.widgetId + this.instance).match(/\d+/g)[0][0];
      if (index > options.length) {
        return options[0];
      } else {
        return options[index - 1];
      }
    }
  },
  watch: {
    forceOpen: {
      handler: function(newVal) {
        this.$store.commit("setShownWidget", this.widgetId + this.instance);
        this.showModal = newVal;
      },
      immediate: true
    }
  },
  methods: {
    openModal() {
      console.log("openModal", this.widgetId + this.instance);
      this.$store.commit("setShownWidget", this.widgetId + this.instance);
      this.showModal = true;
      //TODO should this include instance?
      if (
        this.$store.getters[this.widgetId + "/order/groupedCart"].length === 0
      ) {
        this.restartFlow(true);
      }
    },
    changeLanguage(language) {
      Settings.defaultLocale = language.key;
      this.$store.commit("global/changeLanguage", language.key);
    }
  },
  mounted() {
    //To be able to build production, see https://github.com/FortAwesome/vue-fontawesome#web-components-with-vue-web-component-wrapper
    const { shadowRoot } = this.$parent.$options;
    const id = "fa-styles";
    if (process.env.NODE_ENV === "production") {
      const faStyles = document.createElement("style");
      faStyles.setAttribute("id", id);
      faStyles.textContent = dom.css();
      shadowRoot.appendChild(faStyles);
    }
  },
  created() {
    this.$store.registerModule(this.widgetId + this.instance, {
      namespaced: true,
      modules: {
        global: Global,
        order: Order,
        event: Event
      }
    });
    //TODO some of this logic should be moved to when the modal is opened
    const instanceId = this.widgetId + this.instance;
    console.log("instanceId", instanceId);
    this.$store.commit(instanceId + "/global/setWidgetUuid", this.widgetId);
    this.$store.commit(instanceId + "/global/setInstanceId", instanceId);
    this.$store.dispatch(instanceId + "/global/obtainSessionToken").then(() => {
      console.log("sessionToken continued");
      this.$ws.connector.options.auth.headers.Authorization = `Bearer ${
        this.$store.getters[instanceId + "/global/sessionToken"]
      }`;
      this.$ws
        .channel(
          `session.${this.$store.getters[instanceId + "/global/sessionToken"]}`
        )
        .listen("reservation_expired", () => {
          this.$store.commit(instanceId + "/order/setReservationExpired", true);
        });
      this.$store
        .dispatch(instanceId + "/global/getWidgetSettings", instanceId)
        .then(() => {
          this.widgetSettingsFetched = true;
          setTimeout(() => {
          this.$store
              .dispatch(
                  instanceId + "/event/fetchEvent",
                  this.$store.getters[instanceId + "/global/initialEventUuid"]
              )
              .then(() => {
                this.$store
                    .dispatch(
                        instanceId + "/event/fetchShows",
                        this.$store.getters[instanceId + "/global/initialEventUuid"]
                    )
                    .then(() => {
                      let shows = this.$store.getters[instanceId + "/event/shows"];
                      if (shows.length === 1) {
                        this.$store
                            .dispatch(instanceId + "/event/fetchTicketsForShow", {
                              eventUuid: this.$store.getters[
                              instanceId + "/event/eventUuid"
                                  ],
                              showUuid: shows[0].uuid
                            })
                            .then(() => {
                              this.$store.commit(
                                  instanceId + "/event/setSelectedShowUuid",
                                  shows[0].uuid
                              );
                              this.$store.commit(
                                  instanceId + "/global/changePage",
                                  "Tickets"
                              );
                            });
                      } else {
                        let days = groupBy(shows, show => {
                          return show.starts_at.toISODate();
                        });
                        if (Object.keys(days).length === 1) {
                          this.$store.commit(
                              instanceId + "/global/changePage",
                              "ShowList"
                          );
                        } else {
                          this.$store.commit(
                              instanceId + "/global/changePage",
                              "Calendar"
                          );
                        }
                      }
                    });
              });
        }, 250);
        })
        .catch(() => {
          this.widgetSettingsFetched = true;
          this.problemInitializingWidget = true;
        });
    });
  }
};
</script>
