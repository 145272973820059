import { EventHttpClient } from "@/http/EventHttpClient";
import { OrderQuestionHttpClient } from "@/http/OrderQuestionHttpClient";

export const Event = {
  namespaced: true,
  state: () => ({
    event: {},
    shows: [],
    organizerSlug: {},
    ticketStatusByDate: {},
    selectedShowUuid: null,
    additionalItems: [],
    relatedEvents: [],
    showDateLimiter: null,
    orderQuestions: [],
    orderQuestionsFetched: false
  }),
  getters: {
    saleIsStopped(state) {
      if (state.shows.length === 0) {
        return false;
      }
      return (
        state.shows.filter(
          x =>
            x.sales_stops_at > dateTime.utc().setZone(state.shows[0].timezone)
        ).length === 0
      );
    },
    ticketStatusByDate(state) {
      return state.ticketStatusByDate;
    },
    event(state) {
      return state.event;
    },
    shows(state) {
      return state.shows.sort((a, b) => {
        return a.starts_at - b.starts_at;
      });
    },
    eventUuid(state) {
      return state.event.uuid;
    },
    showDateLimiter(state) {
      return state.showDateLimiter;
    },
    ticketsForActiveShow(state) {
      return state.shows.find(x => x.uuid === state.selectedShowUuid).tickets;
    },
    additionalItems(state) {
      return state.additionalItems;
    },
    activeShow(state) {
      return state.shows.find(x => x.uuid === state.selectedShowUuid);
    },
    orderQuestions(state) {
      return state.orderQuestions;
    },
    hasTerms(state) {
      return state.event.hasTerms;
    },
    newsletterSignupText(state) {
      return state.event.newsletterSignupText;
    },
    eventSlug(state) {
      return state.event.slug;
    },
    organizerSlug(state) {
      return state.event.organizerSlug;
    },
    organizerName(state) {
      return state.event.organizerName;
    }
  },
  actions: {
    fetchOrderQuestions(context, eventUuid) {
      if (context.state.orderQuestionsFetched) {
        return Promise.resolve();
      }
      return OrderQuestionHttpClient.fetchQuestions(eventUuid).then(
        response => {

          return new Promise(resolve => {
            context.commit("setOrderQuestions", response.data);
            resolve();
          })
        }
      );
    },
    fetchEvent(context, eventUuid) {
      return EventHttpClient.fetchEvent(eventUuid).then(response => {

          return new Promise(resolve => {
            context.commit("setEvent", response.data);
            resolve();
          })
      });
    },
    fetchShows(context, eventUuid) {
      return EventHttpClient.fetchShows(eventUuid).then(response => {

          return new Promise(resolve => {
            context.commit("setShows", response.data.shows);
            context.commit(
              "setTicketStatusByDate",
              response.data.ticketStatusForDates
            );
            resolve();
          })
      });
    },
    fetchTicketsForShow(context, { eventUuid, showUuid }) {
      if (
        Array.isArray(
          context.state.shows.filter(x => x.uuid === showUuid).tickets
        )
      ) {
        return false;
      }
      return EventHttpClient.fetchTicketsForShow(eventUuid, showUuid).then(
        response => {

          return new Promise(resolve => {
            context.commit("setTicketsForShow", {
              showUuid: showUuid,
              tickets: response.data.tickets
            });
            resolve()
          })
        }
      );
    },
    fetchAdditionalItems(context, eventUuid) {
      return EventHttpClient.fetchAdditionalItems(eventUuid).then(response => {

        return new Promise(resolve => {
          context.commit("setAdditionalItems", response.data);
          resolve()
        })
      });
    }
  },
  mutations: {
    setOrderQuestions(state, questions) {
      state.orderQuestions = questions;
      state.orderQuestionsFetched = true;
    },
    setEvent(state, event) {
      state.event = event;
    },
    setShowDateLimiter(state, limit) {
      state.showDateLimiter = limit;
    },
    setShows(state, shows) {
      state.shows = shows
        .map(x => {
          x.starts_at = dateTime.fromISO(x.starts_at, { zone: x.timezone });
          x.ends_at = dateTime.fromISO(x.ends_at, { zone: x.timezone });
          x.sales_stops_at = dateTime.fromISO(x.sales_stops_at, {
            zone: x.timezone
          });
          return x;
        })
        .sort((a, b) => a.starts_at - b.starts_at);
    },
    setTicketStatusByDate(state, statuses) {
      state.ticketStatusByDate = statuses;
    },
    setTicketsForShow(state, { showUuid, tickets }) {
      let actualShow = state.shows.find(x => x.uuid === showUuid);
      actualShow.tickets = tickets;
      state.shows = [
        ...state.shows.filter(x => x.uuid !== showUuid),
        ...[actualShow]
      ];
    },
    setSelectedShowUuid(state, uuid) {
      state.selectedShowUuid = uuid;
    },
    setAdditionalItems(state, items) {
      state.additionalItems = items;
    }
  }
};
